import i18n from '@/plugins/localization'

export const tableHeader = {
  qualification: [
    { value: 'created_at', text: i18n.t('createDate') },
    { value: 'number_document', text: i18n.t('docNumber'), sortable: false },
    { value: 'sailorFullName', text: i18n.t('sailorName'), sortable: false },
    { value: 'type_document', text: i18n.t('typeDoc'), sortable: false },
    { value: 'rank', text: i18n.t('rank'), sortable: false },
    { value: 'sqc_positions', text: i18n.t('position'), sortable: false },
    { value: 'status_document', text: i18n.t('status') },
    { value: 'event', text: i18n.t('actions'), class: 'mw-0', sortable: false }
  ],
  education: [
    { value: 'created_at', text: i18n.t('createDate') },
    { value: 'sailorFullName', text: i18n.t('sailorName'), sortable: false },
    { value: 'number_document', text: i18n.t('docNumber'), sortable: false },
    { value: 'qualification.name_ukr', text: i18n.t('qualification'), sortable: false },
    { value: 'name_nz.name_ukr', text: i18n.t('nameInstitution'), sortable: false },
    { value: 'status_document', text: i18n.t('status') },
    { value: 'event', text: i18n.t('actions'), class: 'mw-0', sortable: false }
  ],
  medical: [
    { value: 'created_at', text: i18n.t('createDate') },
    { value: 'sailorFullName', text: i18n.t('sailorName'), sortable: false },
    { value: 'number', text: i18n.t('docNumber'), sortable: false },
    { value: 'position', text: i18n.t('position'), sortable: false },
    { value: 'doctor', text: i18n.t('doctor'), sortable: false },
    { value: 'medical_institution', text: i18n.t('nameInstitution'), sortable: false },
    { value: 'status_document', text: i18n.t('status') },
    { value: 'event', text: i18n.t('actions'), class: 'mw-0', sortable: false }
  ],
  sailorPassport: [
    { value: 'created_at', text: i18n.t('createDate') },
    { value: 'sailorFullName', text: i18n.t('sailorName'), sortable: false },
    { value: 'number_document', text: i18n.t('docNumber'), sortable: false },
    { value: 'country', text: i18n.t('country'), sortable: false },
    { value: 'port', text: i18n.t('port'), sortable: false },
    { value: 'status_document', text: i18n.t('status') },
    { value: 'event', text: i18n.t('actions'), class: 'mw-0', sortable: false }
  ],
  internationalPassport: [
    { value: 'created_at', text: i18n.t('createDate') },
    { value: 'sailorFullName', text: i18n.t('sailorName'), sortable: false },
    { value: 'number', text: i18n.t('docNumber'), sortable: false },
    { value: 'sailorFullNameEng', text: i18n.t('nameEN'), sortable: false },
    { value: 'date_end', text: i18n.t('dateTermination'), sortable: false },
    { value: 'status_document', text: i18n.t('status') },
    { value: 'event', text: i18n.t('actions'), class: 'mw-0', sortable: false }
  ],
  serviceRecord: [
    { value: 'created_at', text: i18n.t('createDate') },
    { value: 'sailorFullName', text: i18n.t('sailorName'), sortable: false },
    { value: 'name_book', text: i18n.t('docNumber'), sortable: false },
    { value: 'branch_office', text: i18n.t('affiliate'), sortable: false },
    { value: 'date_issued', text: i18n.t('dateIssue'), sortable: false },
    { value: 'status_document', text: i18n.t('status') },
    { value: 'event', text: i18n.t('actions'), class: 'mw-0', sortable: false }
  ],
  lineServiceRecord: [
    { value: 'created_at', text: i18n.t('createDate') },
    { value: 'sailorFullName', text: i18n.t('sailorName'), sortable: false },
    { value: 'all_responsibility', text: i18n.t('responsibility'), sortable: false },
    { value: 'date_start', text: i18n.t('dateIssue'), sortable: false },
    { value: 'date_end', text: i18n.t('dateTermination'), sortable: false },
    { value: 'status_line', text: i18n.t('status') },
    { value: 'event', text: i18n.t('actions'), class: 'mw-0', sortable: false }
  ],
  navigatorCertificate: [
    { value: 'created_at', text: i18n.t('createDate') },
    { value: 'sailorFullName', text: i18n.t('sailorName'), sortable: false },
    { value: 'number_document', text: i18n.t('docNumber'), sortable: false },
    { value: 'status_document', text: i18n.t('status') },
    { value: 'event', text: i18n.t('actions'), class: 'mw-0', sortable: false }
  ],
  civilPassport: [
    { value: 'created_at', text: i18n.t('createDate') },
    { value: 'sailor.full_name_ukr', text: i18n.t('nameUK'), sortable: false },
    { value: 'serial', text: i18n.t('serial'), sortable: false },
    { value: 'sailor.full_name_eng', text: i18n.t('nameEN'), sortable: false },
    { value: 'sailor.birth_date', text: i18n.t('dateBorn'), sortable: false },
    { value: 'status_document', text: i18n.t('status') },
    { value: 'event', text: i18n.t('actions'), class: 'mw-0', sortable: false }
  ]
}
