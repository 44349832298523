<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
)
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { tableHeader } from './constants'

export default {
  name: 'DocumentVerification',
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      nameRoute: '',
      headers: [
        { value: 'created_at', text: this.$t('tableHeaders.created_at'), sortable: false }
      ],
      actions: [
        {
          id: 1,
          to: (item) => {
            if (!item.sailor?.id) {
              this.$notification.warning('notify.warning.notInfoAboutSailor')
              return
            }
            return { name: this.nameRoute, params: this.checkParams(item), query: { ...this.$route.query } }
          },
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.documents.itemVerificationDocument,
      isLoading: state => state.documents.isLoading
    })
  },
  watch: {
    '$route.name' (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.SET_DATA_STATE_DOCUMENT({ isLoading: true, itemVerificationDocument: {} })
        this.$router.replace({ query: { page: 1, page_size: 10 } })
        this.getData({ ...this.$route.query })
      }
    }
  },
  methods: {
    ...mapMutations(['SET_DATA_STATE_DOCUMENT']),
    ...mapActions([
      'getListSailorPassportOnVerification', 'getListInternationalPassportOnVerification',
      'getListEducationOnVerification', 'getListQualificationOnVerification',
      'getListNavigatorCertificateOnVerification', 'getListLineServiceRecordOnVerification',
      'getListServiceRecordOnVerification', 'getListMedicalOnVerification', 'getListCitizenPassportOnVerification']),
    async getData (params) {
      switch (this.$route.name) {
        case 'sailor-passport-verification':
          this.nameRoute = 'passports-sailors-info'
          this.headers = tableHeader.sailorPassport
          this.getListSailorPassportOnVerification(params)
          break
        case 'international-passport-verification':
          this.nameRoute = 'passports-international-info'
          this.headers = tableHeader.internationalPassport
          this.getListInternationalPassportOnVerification(params)
          break
        case 'education-verification':
          this.nameRoute = 'education-documents-info'
          this.headers = tableHeader.education
          this.getListEducationOnVerification(params)
          break
        case 'qualification-verification':
          this.nameRoute = 'qualification-documents-info'
          this.headers = tableHeader.qualification
          this.getListQualificationOnVerification(params)
          break
        case 'navigator-certificate-verification':
          this.nameRoute = 'qualification-navigator-info'
          this.headers = tableHeader.navigatorCertificate
          this.getListNavigatorCertificateOnVerification(params)
          break
        case 'service-record-verification':
          this.nameRoute = 'experience-records-info'
          this.headers = tableHeader.serviceRecord
          this.getListServiceRecordOnVerification(params)
          break
        case 'line-service-record-verification':
          this.nameRoute = 'experience-records-line-info'
          this.headers = tableHeader.lineServiceRecord
          this.getListLineServiceRecordOnVerification(params)
          break
        case 'medical-verification':
          this.nameRoute = 'medical-certificates-info'
          this.headers = tableHeader.medical
          this.getListMedicalOnVerification(params)
          break
        case 'civil-passport-verification':
          this.nameRoute = 'passports-citizen-info'
          this.headers = tableHeader.civilPassport
          this.getListCitizenPassportOnVerification(params)
          break
        default:
          break
      }
    },
    checkParams (item) {
      switch (this.$route.name) {
        case 'line-service-record-verification':
          if (item.service_record) return { id: item.sailor.id, documentID: item.service_record, lineID: item.id }
          else {
            this.nameRoute = 'experience-records'
            return { id: item.sailor.id }
          }
        default: return { id: item.sailor.id, documentID: item.id }
      }
    }
  }
}
</script>
